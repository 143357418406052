import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IUser } from '../../model/user';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    initAuth: emptyProps(),
    loginWithCredentials: props<{
      userName: string;
      password: string;
      rememberUser: boolean;
    }>(),
    explicitLogin: props<{
      token: string;
      returnUrl: string;
      params: {};
    }>(),
    loginSuccess: props<{
      user: IUser;
      rememberUser: boolean;
      returnUrl?: string;
      params?: {};
      ignoreSideEffects?: boolean;
    }>(),
    loginFailure: props<{ error: unknown }>(),
    logout: emptyProps(),
    forceLogin: emptyProps(),
    refreshToken: props<{ isRoutine: boolean }>(),
    tokenRefreshed: props<{
      accessToken: string;
      refreshToken: string;
      isRoutine: boolean;
    }>(),
    restoreUser: props<{ user: IUser }>(),
    forceUserStateCheck: emptyProps(),
    navigateToApp: props<{
      returnUrl?: string;
      params?: {};
      ignoreSideEffects?: boolean;
    }>(),
    resetPassword: props<{ email: string }>(),
    setNewPassword: props<{ token: string; email: string; password: string }>(),
    fetchLoginImages: emptyProps(),
    loginImagesFetchSuccess: props<{ images: string[] }>(),
  },
});
